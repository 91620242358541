import MyApiForm from './MyApiForm.vue';
import Vue from 'vue';
export default function MyApi(config: any, context: any) {
  return {
    name: 'MyApi',
    description: 'My API.  An example of an API implementation.',
    cms: {
      template: 'my-api-form',
      props: {
        defaultId: 1,
      },
    },
    install(vue: any) {
      const store = {
        state: {
          items: {},
        },
        actions: {
          async getItem(context: any, payload: any) {
            const response = await fetch(`https://jsonplaceholder.cypress.io/todos/${payload.id}`);
            const json = await response.json();
            context.commit('SET_ITEM', json);
          },
        },
        mutations: {
          SET_ITEM(state: any, payload: any) {
            Vue.set(state.items, payload.id, payload);
          },
        },
      };
      context.registerModule('myApi', store);
      vue.component('my-api-form', MyApiForm);
    },
  }
}
