







import Vue from 'vue';
import {mapState, mapActions} from 'vuex';
export default Vue.extend({
  name: "my-api-form",
  props: {
    defaultId: [String, Number],
  },
  methods: {
    ...mapActions([
      'getItem',
    ]),
  },
  computed: {
    ...mapState({
      items: (state: any) => state.myApi.items,
    }),
  },
});
