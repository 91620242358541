var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-text-field", {
        attrs: { type: "number" },
        model: {
          value: _vm.defaultId,
          callback: function($$v) {
            _vm.defaultId = $$v
          },
          expression: "defaultId"
        }
      }),
      _vm._v(" "),
      _c("div", {
        domProps: { textContent: _vm._s(_vm.items[_vm.defaultId]) }
      }),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          on: {
            click: function($event) {
              return _vm.getItem({ id: _vm.defaultId })
            }
          }
        },
        [_vm._v("Submit")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }